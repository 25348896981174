import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import styled from 'styled-components'
import { Row, Col } from 'react-flexbox-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

import Page from '../components/Page'
import Container from '../components/Container'
import Section from '../components/Section'

const Text = styled.h5`
  font-size: 1.4em;
  font-weight: 100;
  line-height: 1.5em;
`

const Link = styled.a`
  display: inline-block;
  margin: 15px 15px 0 0;
  color: rgb(49,48,47);
`

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 1em 1.5em;
  font-size: 1em;
  border: 1px solid #ccc;
  margin-bottom: 1em;
  transition: border-color .25s ease-in;

  &:hover,
  &:focus {
    border-color: #111;
  }
`

const Message = styled.textarea`
  display: block;
  width: 100%;
  padding: 1em 1.5em;
  font-size: 1em;
  border: 1px solid #ccc;
  margin-bottom: 1em;
  height: 8em;
  font-family: 'Guardian Sans', sans-serif;
  transition: border-color .25s ease-in;

  &:hover,
  &:focus {
    border-color: #111;
  }
`

const Button = styled.button`
  width: 100%;
  background: #104278;
  color: #fff;
  font-size: 1em;
  padding: 1em;
  border: 1px solid #104278;
  cursor: pointer;
  outline: none;

  &:hover {
    background: none;
    color: #104278;
  }

  &[disabled] {
    background: #ccc;
    cursor: not-allowed;
    border-color: #999;
    color: #777;
  }
`

const Msg = styled.p`
  margin: 2em 0;
`

const ContactPage = () => {
  const [disabled, setDisabled] = useState(false)
  const [showMsg, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    setDisabled(true)

    emailjs.sendForm('yandex', 'template_BmThv3N8', e.target, 'user_ERfP4PhrrYpJ6fNSyOwhx')
      .then((result) => {
          setDisabled(false)
          setShowMsg(true)
          setMsg('Your message has been successfully sent.')
          setTimeout(() => { setShowMsg(false) }, 2000)
      }, (error) => {
          console.error(error.text)
          setDisabled(false)
          setShowMsg(true)
          setMsg('Failed to send your message.')
      })
  }

  return (
    <Page
      title='Get In Touch'
      background='images/pages/Contact.jpg'
    >
      <Container>
        <Section
          title='Contact'
        >
          <Row>
            <Col md={6} sm={12}>
              <form onSubmit={handleSubmit}>
                <Input type='text' name='user_name' placeholder='Name' />
                <Input type='email' name='user_email' placeholder='Email' />
                <Message name='message' placeholder='Message' />
                <Button type='submit' disabled={disabled}>Send</Button>
              </form>
              { showMsg ? <Msg>{msg}</Msg> : <Msg>&nbsp;</Msg> }
            </Col>
            <Col md={6} sm={12}>
              <Text>Hunkins Waterfront Plaza, Suite 556, Main Street,</Text>
              <Text>Charlestown, Nevis</Text>
              <Text>Email: info@missiongate.com</Text>
              <Link target='_blank' href='https://www.facebook.com/missiongateVC/'>
                <FontAwesomeIcon icon={faFacebookF} />
              </Link>
              <Link target='_blank' href='https://twitter.com/MissiongateVC'>
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
            </Col>
          </Row>
        </Section>
      </Container>
    </Page>
  )
}

export default ContactPage
