import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'

import * as S from './Section.styles'

const Section = ({ title, subtitle, children }) => {
  if(title === "Recent Deals"){
      return(
      <S.SectionWithoutMarin>
        { title ? <Text type='headline'>{title}</Text> : null }
        { subtitle ? <Text type='sub'>{subtitle}</Text> : null }
        {children}
      </S.SectionWithoutMarin>
      )
  }else{
    return(
      <S.Section>
      { title ? <Text type='headline'>{title}</Text> : null }
      { subtitle ? <Text type='sub'>{subtitle}</Text> : null }
      {children}
    </S.Section>
      )
  }
}

Section.defaultProps = {
  title: ``,
  subtitle: ``,
}

Section.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default Section
